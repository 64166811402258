import React, { Component } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {dateFilter, textFilter} from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Row, Col, Image} from 'react-bootstrap';
import { Link } from  'react-router-dom';
import axios from "../../api/httpclient";
import API_ENTRY_POINT from "../../constants";

export class Banners extends Component {
  state = {
    banners: [],
    columns: [
      {
        dataField: 'id',
        text: 'Id',
        hidden: true
      },
      {
        dataField: 'image',
        text: 'Image',
        filter: false,
        sort: false,
        formatter: (cellContent, row) => {
          return (
              <Image src={API_ENTRY_POINT + row.image} thumbnail />
          );
        }
      },
      {
        dataField: 'title',
        text: 'Title',
        filter: textFilter(),
        sort: true
      },
      {
        dataField: 'body',
        text: 'Content',
        filter: textFilter(),
        sort: true
      },
      {
        dataField: 'startDate',
        text: 'Start Date',
        filter: dateFilter(),
        sort: true
      },
      {
        dataField: 'endDate',
        text: 'End Date',
        filter: dateFilter(),
        sort: true
      },
      {
        dataField: 'ctaUrl',
        text: 'Link',
        filter: false,
        sort: false,
        formatter: (cellContent, row) => {
          return (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a href={row.ctaUrl} target="_blank">{row.ctaLabel}</a>
          );
        }
      },
      {
        dataField: 'filters',
        text: 'Filters',
        filter: false,
        sort: false,
        formatter: (cellContent, row) => {
          let results = 'All';
          if (false === row.allCarsModels) {
            results = [];
            row.carModels.forEach((carModel, index) => {
              results.push(
                  <>{carModel.text}[{carModel.value}]<br/></>,
              );
            });
          }

          return (
                <>
                  <div className="font-weight-bold">Models</div>
                  {results}
                </>
          );
        }
      },
      {
        dataField: 'df1',
        isDummyField: true,
        text: 'Actions',
        formatter: (cellContent, row) => {
            return (
              <Link to={{
                pathname: "/banner/"+row.id,
                  state: { id: row.id }
              }}>
                <svg className="MuiSvgIcon-root RaButton-smallIcon-220" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path></svg>
              </Link>
            );
        }
      },
      ]
  }

  componentDidMount() {
    axios.get('/banners/list',
      {
        headers: {
          'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
        }
      }).then(response => {
      this.setState({
        banners: response.data
      });
    });
  }

  render() {
    return (
      <div className="container">
        <Row>
          <Col sm={11}><h1>Banners</h1></Col>
          <Col sm={1}>
            <Link to={'/banner-create'} className="MuiButtonBase-root MuiButton-root MuiButton-text RaButton-button-217 MuiButton-textPrimary MuiButton-textSizeSmall MuiButton-sizeSmall">
              Create
            </Link>
          </Col>
        </Row>
        <div style={{marginTop: 20}}>
          <BootstrapTable
            striped
            hover
            keyField='id'
            data={ this.state.banners }
            columns={ this.state.columns }
            filter={ filterFactory() }
            pagination={ paginationFactory() }/>
        </div>
      </div>
    )
  }
}

export default Banners
