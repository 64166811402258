import axios from 'axios';
import API_ENTRY_POINT from "../constants";

const instance = axios.create({
  baseURL: API_ENTRY_POINT,
  headers: {
    Accept:         'application/ld+json',
    'Content-Type': 'application/json',
  },
});

export default instance;
