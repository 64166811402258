import React, {useEffect, useState} from "react"
import instance from "../../api/httpclient";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from  'react-router-dom';
import moment from "moment";

const Contact = ({location}) => {

  const [user, setUser] = useState({});
  const [contact, setContact] = useState({});


  useEffect(() => {
    console.log(location);
    if (location.state) {
      instance.get('/contact_forms/' + location.state.id,
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
          }
        })
        .then(function (response) {
          console.log(response);
          setContact(response.data);

          instance.get(response.data.user,
            {
              headers: {
                'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
              }
            })
            .then(function (r) {
              setUser(r.data);

            });

        });
    }
  },[]);


  const getUser = () => {

    if (user) {
      return (
        <div className="mb-5">
          <div className="text-center mb-4">
            <h2>{user.lastName} {user.firstName}</h2>
          </div>

          <div className="text-left mb-4">
            <div className="row mb-4">
              <div className="col-md-4">
                <label>Language : </label> <br/>
                <label>Email : </label><br/>
              </div>
              <div className="col-md-8">
                <div className="mb-2">{user.language}</div>
                <div className="mb-2">{user.email}</div>
              </div>
            </div>
          </div>

        </div>

      );
    }
  };

  const setDone = (event) => {

    if (contact) {
      instance.put('/contact_forms/'+contact.id,
        {
          doneDate: new Date()
        },
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
          }
        }).then(function (response) {
        console.log(response);

      });
    }

    event.preventDefault();

  };

  const getContact = () => {
    console.log(contact);

    if (contact) {
      return (

        <div className="mb-5">

          <div className="text-center mb-4">

            <h2>Contact</h2>

          </div>

          <div className="text-left mb-4">
            <div className="row mb-4">
              <div className="col-md-4">
                <label>Date : </label> <br/>
                <label>Type : </label><br/>
              </div>
              <div className="col-md-8">
                <div className="mb-2">{moment(contact.contactDate, moment.ISO_8601).format('DD/MM/YYYY HH:mm:ss')}</div>
                <div className="mb-2">{contact.recipient}</div>
              </div>

              <div className="col-md-12">
                <label>Message : </label><br/>
                <div className="mb-2">{contact.message}</div>
              </div>

              <div className="col-md-4">
                <label>VoucherId : </label>
              </div>
              <div className="col-md-8">
                <div className="mb-2">{contact.voucher}</div>
              </div>
            </div>
          </div>

          <div className="text-center mb-4">
            { !contact.doneDate &&
            <Link to="#" onClick={(event => setDone(event))} className="button red tick-icon">Set done</Link>
            }
          </div>


        </div>
      );
    }
    else {
      return (
        <div className="mb-5">

          <div className="text-center mb-4">

            <h2>Contact</h2>

          </div>

          <h3>No message</h3>
        </div>
      );
    }
  };

  return (
    <div className="white-container mb-5 p-3">
      <Breadcrumb>
        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="#/contacts">
            Contact
          </Breadcrumb.Item>
        <Breadcrumb.Item active>{user.lastName} {user.firstName}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="row text-center">
        <div className="col-md-6">
          <div className="card">
            { getUser()}
          </div>
        </div>
        <div className="col-md-6">
          <div className="card">
            { getContact()}
          </div>
        </div>
      </div>
    </div>

  )
}

export default Contact

