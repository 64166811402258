import React from "react"

import Contacts from '../../pages/Contacts/Contacts';

const ContactsYouPlus = () => {

  return (

    <Contacts recipient='youplus'/>

  );
};

export default ContactsYouPlus
