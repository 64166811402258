// in src/Menu.js
import React, { createElement } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { MenuItemLink, getResources, usePermissions } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import LabelIcon from '@material-ui/icons/Label';



const Menu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);

  const { permissions } = usePermissions();

  return (
    <div>

      {permissions ? permissions.indexOf('ROLE_MINDOZA') !== -1 &&
      <MenuItemLink
        to="/dashboard"
        primaryText="Dashboard"
        leftIcon={<LabelIcon/>}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      : ''}

      {permissions ? permissions.indexOf('ROLE_MINDOZA') !== -1 &&
      <MenuItemLink
        to="/offers"
        primaryText="Offers"
        leftIcon={<LabelIcon/>}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      : ''}

      {permissions ? permissions.indexOf('ROLE_ADMIN') !== -1 &&
        <MenuItemLink
            to="/banners"
            primaryText="Banners"
            leftIcon={<LabelIcon/>}
            onClick={onMenuClick}
            sidebarIsOpen={open}
        />
      : ''}

      {/* {permissions ? permissions.indexOf('ROLE_MINDOZA') !== -1 &&
        <MenuItemLink
          to="/customers-journey"
          primaryText="Customer Journey"
          leftIcon={<LabelIcon/>}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
        : ''} */}

      {/* {permissions ? permissions.indexOf('ROLE_ADMIN') !== -1 &&
        <MenuItemLink
          to="/customers-journey"
          primaryText="Customer Journey"
          leftIcon={<LabelIcon/>}
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
        : ''} */}

      {permissions ? permissions.indexOf('ROLE_CUSTOMER_SERVICE') !== -1 &&
      <MenuItemLink
        to="/contacts-youplus"
        primaryText="Contacts You+"
        leftIcon={<LabelIcon/>}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      : '' }

      {permissions ? permissions.indexOf('ROLE_BASIS') !== -1 &&
      <MenuItemLink
        to="/rewards-list"
        primaryText="Rewards"
        leftIcon={<LabelIcon/>}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      : ''}
      {permissions ? permissions.indexOf('ROLE_BASIS') !== -1 &&
      <MenuItemLink
        to="/contacts-basis"
        primaryText="Contacts Basis"
        leftIcon={<LabelIcon/>}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      : ''}


      {/*{resources.map(resource => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={
            (resource.options && resource.options.label) ||
            resource.name
          }
          leftIcon={
            resource.icon ? <resource.icon /> : <DefaultIcon />
          }
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ))}*/}

      {isXSmall && logout}

    </div>
  );
};

export default Menu;
