import React, {useEffect, useState} from "react"
import instance from "../../api/httpclient";
import { Link, Redirect, useParams } from  'react-router-dom';
import API_ENTRY_POINT from "../../constants";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {Button, Row, Col, Image, Modal} from "react-bootstrap";
import moment from "moment";

const Banner = () => {
  const {id} = useParams();
  const [banner, setBanner] = useState(null);
  const [image, setImage] = useState('');
  const [redirection, setRedirection] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    instance.get('/banners/' + id,
      {
        headers: {
          'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
        }
      })
      .then(function (response) {
        setBanner(response.data);
        instance.get(response.data.image,
          {
            headers: {
              'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
            }
          })
          .then(function (res) {
            setImage(API_ENTRY_POINT + res.data.contentUrl)
          });
      });
  },[]);

  const deleteBanner = () => {
    instance.delete('/banners/' + id, {
      headers: {
        'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
      }
    })
    .then(function (response) {
      handleClose();
      setRedirection(true);
    });
  };

  const getThumbImage = () => {
    if ('' !== image) {
      return (
        <Image src={image} fluid/>
      )
    }
  };

  const getBanner = () => {
    if (banner) {
      if (redirection) {
        return <Redirect to='/banners'/>;
      }

      let results = 'All';
      if (false === banner.allCarsModels) {
        results = 'Chosen models';
      }

      return (
        <div className="white-container mb-5">
          <Breadcrumb>
            <Breadcrumb.Item href="#/dashboard">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="#/banners">
              banners
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{banner.title} </Breadcrumb.Item>
          </Breadcrumb>

          <div className="mb-4">
            <h1>{banner.title}</h1>
            <p>{banner.body}</p>
            <div className="row mb-4">
              <div className="col-md-12">
                {getThumbImage()}
              </div>
            </div>
            <p><label>Date :</label> {moment(banner.startDate, moment.ISO_8601).format('YYYY-MM-DD')} / {moment(banner.endDate, moment.ISO_8601).format('YYYY-MM-DD')}</p>

            <div className="text-center">
              <div className="card card-full-width">
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-4">
                        <label>Link : </label> <br/>
                        <label>Car models : </label> <br/>
                      </div>
                      <div className="col-md-8">
                        <div className="mb-2">
                          {/* eslint-disable-next-line react/jsx-no-target-blank */}
                          <a href={banner.ctaUrl} target="_blank">
                            {banner.ctaLabel}
                          </a>
                        </div>
                        <div className="mb-2">{results}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Row xs={2} md={4} lg={6}>
            <Col>
              <Link to={{
                pathname: "/banner/edit/"+banner.id,
                state: { id: banner.id }
              }} className={"MuiButtonBase-root MuiButton-root MuiButton-text RaButton-button-217 MuiButton-textPrimary MuiButton-textSizeSmall MuiButton-sizeSmall"}>
                <svg className="MuiSvgIcon-root RaButton-smallIcon-214" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path></svg>
                Edit
              </Link>
            </Col>
            <Col>
              <Link to='#' onClick={handleShow} className="MuiButtonBase-root MuiButton-root MuiButton-text RaButton-button-217 MuiButton-textPrimary MuiButton-textSizeSmall MuiButton-sizeSmall delete-icon">
                Delete
              </Link>
            </Col>
          </Row>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Delete Banner</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>The banner will be deleted.</p>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>Close</Button>
              <Button variant="danger" onClick={deleteBanner}>Delete</Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
  };

  return (
    <div className="white-container mb-5 container">
      {getBanner()}
    </div>
  )
}

export default Banner

