import React from "react"


const Home = () => {

  return (
    <div className="container">
      <div className="row" className="hdr">
        <h1>Welcome</h1>
      </div>


    </div>
  )
}

export default Home

