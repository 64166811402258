import React from "react"
import instance from "../../api/httpclient";
import { Redirect } from  'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import {Button, Form} from "react-bootstrap";
import Multiselect from "react-select";

class BannerCreation extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        title: '',
        imageId: '',
        body: '',
        startDate: '',
        endDate: '',
        ctaUrl: '',
        ctaLabel: '',
        selectedCarModels: {},
        selectCarModels: {},
        allCarsModels: true,
        onlyFirstLogin: false,
        language: '',
        redirection: false,
        fileInput: {},
        errors: {},
      };

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleCheck = this.handleCheck.bind(this);
      this.uploadFile = this.uploadFile.bind(this);
    }

  componentDidMount() {
      let currentComponent = this;
      instance.get('/car_models/options',
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
          }
        })
        .then(function (response) {
          currentComponent.setState({selectCarModels: response.data});
        });
  }

  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case 'title':
        errors.title =
          value.length < 3
            ? 'Title is required.'
            : '';
        break;
      case 'startDate':
        errors.startDate =
          value === null || value === ''
            ? 'Start date is required.'
            : '';
        break;
      case 'endDate':
        errors.endDate =
          value === null || value === ''
            ? 'End date is required.'
            : '';
        break;
      case 'language':
        errors.language =
          value === null || value === ''
            ? 'Language-Country is required.'
            : '';
        break;
      case 'selectedCarModels':
        let newValue = Array.from(event.target.selectedOptions, option => option.value);
        // eslint-disable-next-line no-unused-expressions
        (newValue.length === 0 && false === this.state.allCarsModels) ? 'Car models is required.' : '';
        this.setState({selectedCarModels: [...event.target.selectedOptions].map(o => o.value)});
        return;
        break;
      default:
        break;
    }

    this.setState({errors, [name]: value}, ()=> {
      console.log(errors)
    });

    let _state = {};
    _state[event.target.name] = event.target.value;
    this.setState(_state);
  }

  validateForm(errors) {
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );

    return valid;
  }

  handleCheck(event) {
    let _state = {};
    _state[event.target.name] = event.target.checked;
    this.setState(_state);
  }

  uploadFile(event) {
    let currentComponent = this;
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    let formData = new FormData();
    formData.append('file', files[0], files[0].name)

    reader.onload = (ev) => {
      instance.post('/media_objects',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
          }
        })
        .then(function (response) {
          currentComponent.setState({imageId: response.data['@id']});
        });
    };
  }

  handleSubmit(event) {
    event.preventDefault();
    let currentComponent = this;

    if(this.validateForm(this.state.errors)) {
      instance.post('/banners',
        {
          title: this.state.title,
          body: this.state.body,
          imageId: this.state.imageId,
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          ctaLabel: this.state.ctaLabel,
          ctaUrl: this.state.ctaUrl,
          carModels: this.state.selectedCarModels,
          allCarsModels: this.state.allCarsModels,
          onlyFirstLogin: this.state.onlyFirstLogin,
          language: this.state.language,
        },
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
          }
        })
        .then(function (response) {
          console.log(response);
          currentComponent.setState({redirection: true});
        });
    } else {
      console.error('Invalid Form')
    }

  }

  getCarModels() {
    if (this.state.selectCarModels.length > 0) {
      return (
          <Form.Group controlId="selectedCarModels" className={
            this.state.allCarsModels ? 'd-none' : 'd-block'
          }>
            <Form.Label>Car models</Form.Label>
            <Multiselect name='selectedCarModels' className="multi-select" isMulti classNamePrefix="Select2" value={Array.isArray(this.state.selectedCarModels) ? this.state.selectedCarModels : []} options={this.state.selectCarModels} displayValue="key"
                 onChange={(e) => {
                   this.setState({selectedCarModels: e});
                 }}
            />
          </Form.Group>
      );
    }
  }

  getLanguages() {
    return (
      <Form.Group controlId="language">
        <Form.Label>Language</Form.Label>
        <Form.Control as="select" name='language' className="select2" onChange={this.handleChange}>
            <option value="nl-NL">nl-NL</option>
            <option value="nl-BE">nl-BE</option>
            <option value="fr-BE">fr-BE</option>
            <option value="fr-LU">fr-LU</option>
        </Form.Control>
      </Form.Group>
    );
  }

  render() {
    if (this.state.redirection) {
      return <Redirect to='/banners'/>;
    }
    return (

      <div className="white-container mb-5 container" >
        <div className="white-container mb-5">
          <Breadcrumb>
            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="#/banners">
              Banner Creation
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{this.state.title} </Breadcrumb.Item>
          </Breadcrumb>

          <div className="mb-4">
            <Form>
              <h4>Banner</h4>
              <Form.Group controlId="title">
                <Form.Label>Title :</Form.Label>
                <Form.Control type="text" name='title' value={this.state.title} onChange={this.handleChange} />
                {this.state.errors.title ? this.state.errors.title.length > 0 &&
                  <span style={{color:'#c3002f', fontStyle:'italic', padding:'5px'}}>
                  {this.state.errors.title}
                </span> : ''}
              </Form.Group>
              <Form.Group controlId="body">
                <Form.Label>Content :</Form.Label>
                <Form.Control as="textarea" rows="3" name='body' value={this.state.body} onChange={this.handleChange} />
                {this.state.errors.body ? this.state.errors.body.length > 0 &&
                  <span style={{color:'#c3002f', fontStyle:'italic', padding:'5px'}}>
                  {this.state.errors.body}
                </span> : ''}
              </Form.Group>
              <Form.Group controlId="image">
                <Form.Label>Image :</Form.Label>
                <Form.Control type="file" name='fileInput' onChange={this.uploadFile}/>
              </Form.Group>
              <Form.Group controlId="startDate">
                <Form.Label>Start date :</Form.Label>
                <Form.Control type="date" name='startDate' onChange={this.handleChange} />
                {this.state.errors.startDate ? this.state.errors.startDate.length > 0 &&
                    <span style={{color:'#c3002f', fontStyle:'italic', padding:'5px'}}>
                  {this.state.errors.startDate}
                </span> : ''}
              </Form.Group>
              <Form.Group controlId="endDate">
                <Form.Label>End date :</Form.Label>
                <Form.Control type="date" name='endDate' onChange={this.handleChange} />
                {this.state.errors.endDate ? this.state.errors.endDate.length > 0 &&
                    <span style={{color:'#c3002f', fontStyle:'italic', padding:'5px'}}>
                  {this.state.errors.endDate}
                </span> : ''}
              </Form.Group>
              <Form.Group controlId="cta">
                <Form.Label>Cta Url :</Form.Label>
                <Form.Control type="url" name='ctaUrl' value={this.state.ctaUrl} onChange={this.handleChange} />
                {this.state.errors.ctaUrl ? this.state.errors.ctaUrl.length > 0 &&
                    <span style={{color:'#c3002f', fontStyle:'italic', padding:'5px'}}>
                  {this.state.errors.ctaUrl}
                </span> : ''}
              </Form.Group>
              <Form.Group controlId="ctaLabel">
                <Form.Label>Cta label :</Form.Label>
                <Form.Control type="text" name='ctaLabel' value={this.state.ctaLabel} onChange={this.handleChange} />
                {this.state.errors.ctaLabel ? this.state.errors.ctaLabel.length > 0 &&
                    <span style={{color:'#c3002f', fontStyle:'italic', padding:'5px'}}>
                  {this.state.errors.ctaLabel}
                </span> : ''}
              </Form.Group>
              {this.getLanguages()}
              <Form.Group controlId="onlyFirstLogin">
                <Form.Check type="checkbox" name='onlyFirstLogin' checked={this.state.onlyFirstLogin} label="Only first login" onChange={this.handleCheck}/>
              </Form.Group>
              <Form.Group controlId="allCarsModels">
                <Form.Check type="checkbox" name='allCarsModels' checked={this.state.allCarsModels} label="All Car Models" onChange={this.handleCheck}/>
              </Form.Group>
              {this.getCarModels()}

              <Button onClick={this.handleSubmit} disabled={!this.validateForm(this.state.errors) || Object.keys(this.state.errors).length == 0} variant="danger">
                Save
              </Button>
            </Form>
          </div>
        </div>
      </div>
    )
  }
}

export default BannerCreation

