import React from "react"

import Contacts from '../../pages/Contacts/Contacts';

const ContactsBasis = () => {

  return (

    <Contacts recipient='basis'/>

  );
};

export default ContactsBasis
