import React, { Component } from 'react'
import axios from '../../api/httpclient';
import {Col, Row} from 'react-bootstrap';
import { Link } from  'react-router-dom';
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

export class CustomersJourney extends Component {
  state = {
    customers: [],
    columns: [
      {
        dataField: 'id',
        text: 'Id',
        hidden: true
      },
      {
        dataField: 'idlsm',
        text: 'ID LSM',
        filter: textFilter(),
        sort:true
      },
      {
        dataField: 'firstName',
        text: 'First name',
        filter: textFilter(),
        sort:true
      },
      {
        dataField: 'lastName',
        text: 'Last name',
        filter: textFilter(),
        sort:true
      },
      {
        dataField: 'email',
        text: 'Email',
        filter: textFilter(),
        sort:true
      },
      {
        dataField: 'df1',
        isDummyField: true,
        text: 'Actions',
        formatter: (cellContent, row) => {
          return (
            <Link to={{
              pathname: "/customer-journey",
              state: { id: row.id }
            }}>
              <svg className="MuiSvgIcon-root RaButton-smallIcon-220" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"></path></svg>
            </Link>
          );
        }
      },
    ]
  }

  componentDidMount() {
    axios.get('/users?pagination=false&order%5BlastName%5D=ASC',
      {
        headers: {
          'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
        }
      }).then(response => {
      console.log(response.data);
      this.setState({
        customers: response.data['hydra:member']
      });
    });
  }

  render() {
    return (
      <div className="container">
        <Row>
          <Col sm={11}><h1>Customers Journey</h1></Col>
        </Row>
        <div style={{marginTop: 20}}>
          <BootstrapTable
            striped
            hover
            keyField='id'
            data={ this.state.customers }
            columns={ this.state.columns }
            filter={ filterFactory() }
            pagination={ paginationFactory() }/>
        </div>
      </div>
    );
  }
}

export default CustomersJourney
