import React from "react"
import axios from "../../api/httpclient";
import { Redirect } from  'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import moment from "moment";
import { Button, Form, Row, Col, Image } from 'react-bootstrap';
import API_ENTRY_POINT from "../../constants";

class OfferEdition extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        redirection: false,
        imageId: '',
        sliderImageId: '',
        fileInput: {},
        image: {},
        sliderImage: {},
        country: 'nl-NL',
        countries: ['nl-NL', 'nl-BE', 'fr-BE', 'fr-LU'],
        offerDescription: '',
        errors: {},
        formIsTouched: false,
        campaigns: []
      };

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleCheck = this.handleCheck.bind(this);
      this.uploadFile = this.uploadFile.bind(this);

     //let currentComponent = this;

    }

  componentDidMount() {
    if (this.props.location.state) {
      let currentComponent = this;
      axios.get('/offers/' + this.props.location.state.id,
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
          }
        })
        .then(function (response) {
          console.log(response);
          currentComponent.setState(response.data);
          axios.get('/campaigns',
            {
              headers: {
                'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
              }
            })
            .then(function (rsps) {
              currentComponent.setState({campaigns: rsps.data['hydra:member']});
            });

          axios.get(response.data.thumbnail,
            {
              headers: {
                'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
              }
            })
            .then(function (res) {
                console.log(res.data);
                currentComponent.setState({imageId: res.data['@id']});
                currentComponent.setState({image: API_ENTRY_POINT + res.data.contentUrl});
              }
            );
          axios.get(response.data.sliderImage,
            {
              headers: {
                'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
              }
            })
            .then(function (res) {
                console.log(res.data);
                currentComponent.setState({sliderImageId: res.data['@id']});
                currentComponent.setState({sliderImage: API_ENTRY_POINT + res.data.contentUrl});
              }
            )
        });
    }
  }

  handleChange(event) {
    this.setState({formIsTouched: true});
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case 'name':
        errors.name =
          value.length < 3
            ? 'Name is required.'
            : '';
        break;
      case 'description':
        errors.description =
          value.length < 3
            ? 'Description is required.'
            : '';
        break;
      case 'startDate':
        errors.startDate =
          value === null || value === ''
            ? 'Start date is required.'
            : '';
        break;
      case 'endDate':
        errors.endtDate =
          value === null || value === ''
            ? 'End date is required.'
            : '';
        break;
      case 'country':
        errors.country =
          value === null || value === ''
            ? 'Country is required.'
            : '';
        errors.country =
          value.length > 5
            ? 'Its to long to be a country code.'
            : '';
        break;
      default:
        break;
    }

    this.setState({errors, [name]: value}, ()=> {
      console.log(errors);
    });

    let _state = {};
    _state[event.target.name] = event.target.value;
    this.setState(_state);
  }

  validateForm(errors) {
    let valid = true;
    Object.values(errors).forEach(
      // if we have an error string set valid to false
      (val) => val.length > 0 && (valid = false)
    );

    return valid;
  }

  handleCheck(event) {
    let _state = {};
    _state[event.target.name] = event.target.checked;
    this.setState(_state);
  }

  uploadFile(event) {
    this.setState({formIsTouched: true});
    let currentComponent = this;
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    let targetName = event.target.name;

    let formData = new FormData();
    formData.append('file', files[0], files[0].name)

    reader.onload = (ev) => {
      axios.post('/media_objects',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
          }
        })
        .then(function (response) {
          if ('fileInput' === targetName) {
            currentComponent.setState({imageId: response.data['@id']});
          } else {
            currentComponent.setState({sliderImageId: response.data['@id']});
          }

          axios.get(response.data['@id'],
            {
              headers: {
                'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
              }
            })
            .then(function (res) {
                console.log(res.data);
              if (undefined !== res.data.contentUrl) {
                if ('fileInput' === targetName) {
                  currentComponent.setState({image: API_ENTRY_POINT + res.data.contentUrl});
                } else {
                  currentComponent.setState({sliderImage: API_ENTRY_POINT + res.data.contentUrl});
                }
              }
              }
            )
        });
    };
  }

  handleSubmit(event) {
    //event.preventDefault();
    let currentComponent = this;

    if(this.validateForm(this.state.errors)) {
    axios.put('/offers/' + this.state.id,
      {
        name: this.state.name,
        thumbnail: this.state.imageId,
        sliderImage: this.state.sliderImageId,
        description: this.state.description,
        campaign: this.state.campaign.split('/')[2],
        ctaLabel: this.state.ctaLabel,
        ctaUrl: this.state.ctaUrl,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        country: this.state.country,
        include: this.state.include,
        getAQuote: this.state.getAQuote,
        bookAppointment: this.state.bookAppointment,
        userStatus: this.state.userStatus,
        detailTitle: this.state.detailTitle,
        detailDescription: this.state.detailDescription,
        offerDescription: this.state.offerDescription
      },
      {
        headers: {
          'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
        }
      })
      .then(function (response) {
        console.log(response);
        currentComponent.setState({redirection: true});
      });
    } else {
      console.error('Invalid Form')
    }
  };

  getCampaigns() {
    if (this.state.campaigns.length > 0) {
      return (
        <Form.Group controlId="campaign">
          <Form.Label>Campaign</Form.Label>
          <Form.Control as="select" name='campaign' value={this.state.campaign} onChange={this.handleChange}>
            {this.state.campaigns.map((campaign) => (
              <option value={campaign['@id']}>{campaign.title}</option>
            ))}
          </Form.Control>
        </Form.Group>
      );
    }
  }

  showThumbnailImage() {
    if ('/' !== this.state.imageId) {
      return (
        <Image src={this.state.image} thumbnail />
      );
    }
  }

  showSliderImage() {
    if ('/' !== this.state.sliderImageId) {
      return (
        <Image src={this.state.sliderImage} thumbnail />
      );
    }
  }

  render() {
    if (this.state.redirection) {
      //Affichage de la redirection
      return <Redirect to='#/offers'/>;
    }
    return (


      <div className="white-container mb-5 container">

        <div className="white-container mb-5">
          <Breadcrumb>
            <Breadcrumb.Item href="#/dashboard">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="#/offers">
              Offers Edition
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{this.state.name} </Breadcrumb.Item>
          </Breadcrumb>

          <div className="mb-4">
            <Form>

              {/*
              <Form.Group controlId="country">
                <Form.Label>Country :</Form.Label>
                <Form.Control type="text" name='country' value={this.state.country} onChange={this.handleChange} />
                {this.state.errors.country ? this.state.errors.country.length > 0 &&
                    <span style={{color:'#c3002f', fontStyle:'italic', padding:'5px'}}>
                  {this.state.errors.country}
                </span> : ''}
              </Form.Group>
              */}

              <Form.Group controlId="country">
                <Form.Label>Country :</Form.Label>
                <Form.Control as="select" name='country' value={this.state.country} onChange={this.handleChange}>
                  {this.state.countries.map((country) => (
                      <option value={country}>{country}</option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Row>
                <Col xs={6}>
                  <h4>Slider</h4>
                  {this.showSliderImage()}
                  <Form.Group controlId="sliderImage">
                    <Form.Label>Change slider Image :</Form.Label>
                    <Form.Control type="file" name='fileInput2' onChange={this.uploadFile}/>
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <h4>Offer details</h4>
                  {this.showThumbnailImage()}
                  <Form.Group controlId="thumbnail">
                    <Form.Label>Change offer Image :</Form.Label>
                    <Form.Control type="file" name='fileInput' onChange={this.uploadFile}/>
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group controlId="name">
                <Form.Label>Offer short title :</Form.Label>
                <Form.Control type="text" name='name' value={this.state.name} onChange={this.handleChange} />
                {this.state.errors.name ? this.state.errors.name.length > 0 &&
                  <span style={{color:'#c3002f', fontStyle:'italic', padding:'5px'}}>
                  {this.state.errors.name}
                </span> : ''}
              </Form.Group>
              <Form.Group controlId="offerDescription">
                <Form.Label>Short description :</Form.Label>
                <Form.Control as="textarea" rows="3" name='offerDescription' value={this.state.offerDescription} onChange={this.handleChange} />
                {this.state.errors.offerDescription ? this.state.errors.offerDescription.length > 0 &&
                    <span style={{color:'#c3002f', fontStyle:'italic', padding:'5px'}}>
                  {this.state.errors.offerDescription}
                </span> : ''}
              </Form.Group>
              <Form.Group controlId="detailTitle">
                <Form.Label>Offer title :</Form.Label>
                <Form.Control type="text"  name='detailTitle' value={this.state.detailTitle} onChange={this.handleChange} />
              </Form.Group>
              <Form.Group controlId="detailDescription">
                <Form.Label>Long description :</Form.Label>
                <Form.Control as="textarea" rows="3" name='detailDescription' value={this.state.detailDescription} onChange={this.handleChange} />
              </Form.Group>
              <Form.Group controlId="description">
                <Form.Label>Offer details (Offer of the moment) :</Form.Label>
                <Form.Control as="textarea" rows="3" name='description' value={this.state.description} onChange={this.handleChange} />
                {this.state.errors.description ? this.state.errors.description.length > 0 &&
                    <span style={{color:'#c3002f', fontStyle:'italic', padding:'5px'}}>
                  {this.state.errors.description}
                </span> : ''}
              </Form.Group>

              <Form.Group controlId="startDate">
                <Form.Label>Start date :</Form.Label>
                <Form.Control type="date" name='startDate' value={moment(this.state.startDate, moment.ISO_8601).format('YYYY-MM-DD')} onChange={this.handleChange} />
                {this.state.errors.startDate ? this.state.errors.startDate.length > 0 &&
                    <span style={{color:'#c3002f', fontStyle:'italic', padding:'5px'}}>
                  {this.state.errors.startDate}
                  </span> : ''}
              </Form.Group>
              <Form.Group controlId="endDate">
                <Form.Label>End date :</Form.Label>
                <Form.Control type="date" name='endDate' value={moment(this.state.endDate, moment.ISO_8601).format('YYYY-MM-DD')} onChange={this.handleChange} />
                {this.state.errors.endDate ? this.state.errors.endDate.length > 0 &&
                    <span style={{color:'#c3002f', fontStyle:'italic', padding:'5px'}}>
                  {this.state.errors.endDate}
                </span> : ''}
              </Form.Group>

              <Form.Group controlId="include">
                <Form.Check type="checkbox" name='include' checked={this.state.include} label="include" onChange={this.handleCheck}/>
              </Form.Group>
              <Form.Group controlId="getAQuote">
                <Form.Check type="checkbox" name='getAQuote' checked={this.state.getAQuote} label="getAQuote" onChange={this.handleCheck}/>
              </Form.Group>
              <Form.Group controlId="bookAppointment">
                <Form.Check type="checkbox" name='bookAppointment' checked={this.state.bookAppointment} label="bookAppointment" onChange={this.handleCheck}/>
              </Form.Group>
              <Form.Group controlId="userStatus">
                <Form.Label>User Status</Form.Label>
                <Form.Control as="select" name='userStatus' value={this.state.userStatus} onChange={this.handleChange}>
                  <option value="1">1 - Bronze</option>
                  <option value="2">2 - Silver</option>
                  <option value="3">3 - Gold</option>
                  <option value="4">4 - Premium</option>
                </Form.Control>
              </Form.Group>
              {this.getCampaigns()}

              <Form.Group controlId="ctaLabel">
                <Form.Label>Label CTA :</Form.Label>
                <Form.Control type="text"  name='ctaLabel' value={this.state.ctaLabel} onChange={this.handleChange} />
              </Form.Group>
              <Form.Group controlId="ctaUrl">
                <Form.Label>URL CTA :</Form.Label>
                <Form.Control type="text"  name='ctaUrl' value={this.state.ctaUrl} onChange={this.handleChange} />
              </Form.Group>

              <Button onClick={this.handleSubmit} disabled={!this.validateForm(this.state.errors) || this.state.formIsTouched === false} variant="danger">
                Send
              </Button>
            </Form>
          </div>
        </div>

      </div>

    )
  }
}

export default OfferEdition

