import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Logo from '../../assets/images/logo-nissan.png';

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  appBar: {
    backgroundColor: '#fff'
  },
  mainLogo: {
    height: '50px'
  }
});

const MyAppBar = props => {
  const classes = useStyles();

  return (
    <AppBar {...props} className={classes.appBar}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <img src={Logo} className={classes.mainLogo} />
      <span className={classes.spacer} />
    </AppBar>
  );
};

export default MyAppBar;
