import React, {useEffect, useState} from "react"
import instance from "../../api/httpclient";
import Table from 'react-bootstrap/Table';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from  'react-router-dom';
import { Layout } from 'react-admin';
import moment from "moment";

const UserRewards = ({location}) => {

  const [user, setUser] = useState({});
  const [rewards, setRewards] = useState({});


  useEffect(() => {
    console.log(location);
    if (location.state) {
      instance.get('/users/' + location.state.id,
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
          }
        })
        .then(function (response) {
          console.log(response);
          setUser(response.data);

          instance.get(`/user-rewards/` + location.state.id,
            {
              headers: {
                'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
              }
            })
            .then(function (r) {
              console.log(r);
              setRewards(r.data.vouchers);


            });

        });
    }
  },[]);

  const sendVoucher = (event, voucherId) => {
    console.log(location);
    if (location.state) {
      instance.get('/rewards/voucher/'+voucherId+'/user/' + user.id,
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
          }
        })
        .then(function (response) {
          console.log(response);

        });
    }

    event.preventDefault();
  };


  const getUser = () => {

    if (user) {
      return (
        <div className="white-container mb-5">
          <Breadcrumb>
            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="#/rewards-list">
              Rewards
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{user.lastName} {user.firstName}</Breadcrumb.Item>
          </Breadcrumb>
          <div className="text-center">
            <div className="card">
              <div className="text-center mb-5">
                <h2>{user.lastName} {user.firstName}</h2>
              </div>
              <div className="row mb-4">
                <div className="col-md-4">
                  <label>Status : </label> <br/>
                  <label>Language : </label><br/>
                  <label>Email : </label>
                </div>
                <div className="col-md-8">
                  <div className="mb-2">{user.status === 1 ? 'BRONZE' : (user.status === 2 ? 'SILVER' : (user.status === 3 ? 'GOLD' : (user.status === 4 ? 'PREMIUM' : '')))}</div>
                  <div className="mb-2">{user.language}</div>
                  <div>{user.email}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const getRewards = () => {
    console.log(rewards);

    if (rewards.length > 0) {
      return (

        <div className="white-container mb-5">
          <div className="col-md-12">
            <div className="text-center mb-4">

              <h2>Vouchers</h2>

            </div>

            <Table striped bordered hover size="sm">
              <thead>
              <tr>
                <th>Date</th>
                <th>Label</th>
                <th>Type</th>
                <th>Image</th>
                <th>VoucherId</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              {rewards.map((reward) => (
                <tr key={reward.voucher_id}>
                  <td>{moment(reward.voucher_date, moment.ISO_8601).format('DD/MM/YYYY HH:mm:ss')}</td>
                  <td>{reward.reward_label}</td>
                  <td>{reward.reward_status === '1' ? 'BRONZE' : (reward.reward_status === '2' ? 'SILVER' : (reward.reward_status === '3' ? 'GOLD' : (reward.reward_status === '4' ? 'PREMIUM' : '')))}</td>
                  <td><img width={136} height={90} src={reward.reward_img}/></td>
                  <td>{reward.voucher_id}</td>
                  <td><Link to="#" onClick={(event => sendVoucher(event, reward.voucher_id))}>Send voucher</Link></td>
                </tr>
              ))}
              </tbody>
            </Table>
          </div>
        </div>
      );
    }
    else {
      return (
        <div className="white-container mb-5">
          <div className="col-md-12">
            <div className="text-center mb-4">

              <h2>Vouchers</h2>

            </div>

            <h4>No vouchers</h4>
          </div>
        </div>
      );
    }
  };

  return (


    <div className="white-container mb-5">

      { getUser()}
      { getRewards()}

    </div>

  )
}

export default UserRewards

