import React from 'react';
import { Route } from 'react-router-dom';
import Dashboard from '../../pages/Dashboard';
import Home from '../../pages/Home';
import Rewards from '../../pages/Rewards/Rewards';
import Offers from '../../pages/Offers/Offers';
import Offer from '../../pages/Offers/Offer';
import OfferEdition from '../../pages/Offers/OfferEdition';
import OfferCreation from '../../pages/Offers/OfferCreation';
import UserRewards from '../../pages/Rewards/UserRewards';
import Contact from '../../pages/Contacts/Contact';
import ContactsBasis from '../../pages/Contacts/ContactsBasis';
import ContactsYouPlus from '../../pages/Contacts/ContactsYouPlus';
import CustomersJourney from '../../pages/CustomersJourney/CustomersJourney';
import CustomerJourney from '../../pages/CustomersJourney/CustomerJourney';
import Banners from '../../pages/Banners/Banners';
import Banner from '../../pages/Banners/Banner';
import BannerEdition from '../../pages/Banners/BannerEdition';
import BannerCreation from '../../pages/Banners/BannerCreation';

export default [
  <Route exact path="/home" component={Home} />,
  <Route exact path="/dashboard" component={Dashboard} />,
  <Route exact path="/rewards-list" component={Rewards} />,
  <Route exact path="/offers" component={Offers} />,
  <Route exact path="/customers-journey" component={CustomersJourney} />,
  <Route exact path="/customer-journey" component={CustomerJourney} />,
  <Route exact path="/offer" component={Offer} />,
  <Route exact path="/offer-edit" component={OfferEdition} />,
  <Route exact path="/offer-create" component={OfferCreation} />,
  <Route exact path="/user-rewards" component={UserRewards} />,
  <Route exact path="/contact" component={Contact} />,
  <Route exact path="/contacts-basis" component={ContactsBasis} />,
  <Route exact path="/contacts-youplus" component={ContactsYouPlus} />,
  <Route exact path="/banners" component={Banners} />,
  <Route exact path="/banner/:id" component={Banner} />,
  <Route exact path="/banner/edit/:id" component={BannerEdition} />,
  <Route exact path="/banner-create" component={BannerCreation} />,
];
