import React, {useEffect, useState} from "react"
import instance from '../../api/httpclient';
import {Redirect} from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import moment from 'moment';
import API_ENTRY_POINT from '../../constants'

const CustomerJourney = ({location}) => {

  const [customerJourney, setCustomerJourney] = useState({});
  const [emailMirrors, setEmailMirrors] = useState([]);
  const [scheduledEmails, setScheduledEmails] = useState([]);
  const [statusHistory, setStatusHistory] = useState([]);
  const [cars, setCars] = useState([]);
  const [redirection, setRedirection] = useState(false);

  useEffect(() => {
    console.log(location);
    if (location.state) {
      instance.get('/users/' + location.state.id,
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
          }
        })
        .then(function (response) {
          console.log(response);
          setCustomerJourney(response.data);
          instance.get('/users/' + response.data.id + '/cars',
            {
              headers: {
                'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
              }
            })
            .then(function (rps) {
              console.log(rps.data);
              console.log(rps.data['hydra:member']);
              setCars(rps.data['hydra:member']);
            });

          instance.get('/users/' + response.data.id + '/email/mirror',
            {
              headers: {
                'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
              }
            })
            .then(function (res) {
              console.log(res.data);
              setEmailMirrors(res.data.emailMirrors)
            });

          instance.get('/users/' + response.data.id + '/email/scheduled',
            {
              headers: {
                'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
              }
            })
            .then(function (res) {
              console.log(res.data);
              setScheduledEmails(res.data.scheduledEmails)
            });

          instance.get('/users/' + response.data.id + '/status-history',
            {
              headers: {
                'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
              }
            })
            .then(function (resp) {
              console.log(resp.data);
              setStatusHistory(resp.data.userStatusHistory)
            });
        });
    }
  },[]);

  const getCars = () => {
    if (cars.length > 0) {
      if (redirection) {
        //Affichage de la redirection
        return <Redirect to='#/customers-journey'/>;
      }

      return (
        cars.map((car) => (
            <div key={car.id} className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <label>Licence Plate : </label> <br/>
                  <label>Vin : </label> <br/>
                  <label>Ownership Status : </label> <br/>
                  <label>Ownership Status Date : </label> <br/>
                  <label>Current User Added Date : </label> <br/>
                </div>
                <div className="col-md-6">
                  <div className="mb-2">{car.licencePlate !== "" ? car.licencePlate : 'No license plate'}</div>
                  <div className="mb-2">{car.vin}</div>
                  <div className="mb-2">{car.ownershipStatus ? car.ownershipStatus : 'No defined status yet.'}</div>
                  <div className="mb-2">{car.ownershipStatusDate !== null && car.ownershipStatusDate !== undefined ? moment(car.ownershipStatusDate, moment.ISO_8601).format('YYYY-MM-DD H:mm:ss') : ''} </div>
                  <div className="mb-2">{car.currentUserAddedDate !== null && car.currentUserAddedDate !== undefined ? moment(car.currentUserAddedDate, moment.ISO_8601).format('YYYY-MM-DD H:mm:ss') : ''}</div>
                </div>
              </div>
            </div>
          ))
      );
    } else {
      return (
        <div className="text-center">
        No cars.
        </div>
      );
    }
  }

  const getEmailMirrors = () => {
    if (emailMirrors.length > 0) {
      if (redirection) {
        //Affichage de la redirection
        return <Redirect to='#/customers-journey'/>;
      }

      return (
        emailMirrors.map((emailMirror) => (
          <div key={emailMirror.id} className="col-md-12">
            <div className="row">
              <div className="col-md-6">
                <label>Type : </label> <br/>
                <label>Date : </label> <br/>
                <label>Email Mirror Link : </label> <br/>
              </div>
              <div className="col-md-6">
                <div className="mb-2">{emailMirror.type}</div>
                <div className="mb-2">{emailMirror.creationDate !== null && emailMirror.creationDate !== undefined ? moment(emailMirror.creationDate, moment.ISO_8601).format('YYYY-MM-DD H:mm:ss') : ''} </div>
                <div className="mb-2"><a href={API_ENTRY_POINT + '/email/mirror/' + emailMirror.mirrorId} target="_blank"> See email mirror</a></div>
              </div>
            </div>
          </div>
        ))
      );

    }  else {
      return (
        <div className="text-center">
          No Email already sent.
        </div>
      );
    }
  };

  const getScheduledEmails = () => {
    if (scheduledEmails.length > 0) {
      if (redirection) {
        //Affichage de la redirection
        return <Redirect to='#/customers-journey'/>;
      }

      return (
        scheduledEmails.map((scheduledEmail) => (
          <div key={scheduledEmail.id} className="col-md-6">
            <div className="row">
              <div className="col-md-6">
                <label>Type : </label> <br/>
                <label>Scheduled Date : </label> <br/>
              </div>
              <div className="col-md-6">
                <div className="mb-2">{scheduledEmail.type}</div>
                <div className="mb-2">{scheduledEmail.scheduledDate !== null && scheduledEmail.scheduledDate !== undefined ? moment(scheduledEmail.scheduledDate, moment.ISO_8601).format('YYYY-MM-DD') : ''} </div>
              </div>
            </div>
          </div>
        ))
      );

    } else {
      return (
        <div className="text-center">
          No scheduled email.
        </div>
      );
    }
  };

  const getStatusHistory = () => {
    if (statusHistory.length > 0) {
      if (redirection) {
        //Affichage de la redirection
        return <Redirect to='#/customers-journey'/>;
      }

      return (
        statusHistory.map((history, key) => (
          <div key={key} className="col-md-6">
            <div className="row">
              <div className="col-md-6">
                <label>Previous Status : </label> <br/>
                <label>New Status : </label> <br/>
                <label>Change date : </label> <br/>
              </div>
              <div className="col-md-6">
                <div className="mb-2">
                  {history.previousStatus === 1 ? ' Bronze' : ''}
                  {history.previousStatus === 2 ? ' Silver' : ''}
                  {history.previousStatus === 3 ? ' Gold' : ''}
                  {history.previousStatus === 4 ? ' Premium' : ''}
                  {history.previousStatus === null ? 'No previous status' : ''}
                </div>
                <div className="mb-2">
                  {history.status === 1 ? ' Bronze' : ''}
                  {history.status === 2 ? ' Silver' : ''}
                  {history.status === 3 ? ' Gold' : ''}
                  {history.status === 4 ? ' Premium' : ''}
                </div>
                <div className="mb-2">{history.levelUpDate !== null && history.levelUpDate !== undefined ? moment(history.levelUpDate, moment.ISO_8601).format('YYYY-MM-DD H:mm:ss') : ''} </div>
              </div>
            </div>
          </div>
        ))
      );

    } else {
      return (
        <div className="text-center">
          No user status history.
        </div>
      );
    }
  };

  const getCustomerJourney = () => {

    if (customerJourney) {
      if (redirection) {
        //Affichage de la redirection
        return <Redirect to='#/customers-journey'/>;
      }
      return (
        <div className="white-container mb-5">
          <Breadcrumb>
            <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="#/customers-journey">
              Customers Journey
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{customerJourney.firstName} {customerJourney.lastName}</Breadcrumb.Item>
          </Breadcrumb>

          <div className="mb-4">
            <h1>{customerJourney.firstName} {customerJourney.lastName}</h1>

            {/*<p><label>Date :</label> {moment(offer.startDate, moment.ISO_8601).format('YYYY-MM-DD')} / {moment(offer.endDate, moment.ISO_8601).format('YYYY-MM-DD')}</p>*/}

            <div className="text-center">
              <div className="card card-full-width">
                <div className="row mb-12">
                      <div className="col-md-4">
                        <label>Id : </label> <br/>
                        <label>LSM ID : </label> <br/>
                        <label>Email : </label> <br/>
                        <label>Status : </label> <br/>
                        <label>Rewards Access End Date : </label> <br/>
                      </div>
                      <div className="col-md-8">
                        <div className="mb-2">{customerJourney.id}</div>
                        <div className="mb-2">{customerJourney.idlsm ? customerJourney.idlsm : 'No LSM ID yet'}</div>
                        <div className="mb-2">{customerJourney.email}</div>
                        <div className="mb-2">
                          {customerJourney.status === 1 ? ' Bronze' : ''}
                          {customerJourney.status === 2 ? ' Silver' : ''}
                          {customerJourney.status === 3 ? ' Gold' : ''}
                          {customerJourney.status === 4 ? ' Premium' : ''}
                        </div>
                        <div className="mb-2">{customerJourney.rewardsAccessEndDate !== null ? moment(customerJourney.rewardsAccessEndDate, moment.ISO_8601).format('YYYY-MM-DD') : 'No date'}</div>
                      </div>
                </div>
              </div>
            </div>

              <div className="mb-4">
                <h1>Cars</h1>
              </div>
              <div className="card card-full-width">
                <div className="row mb-4">
                  {getCars()}
                </div>
              </div>

              <div className="mb-4">
                <h1>Email sent</h1>
              </div>
              <div className="card card-full-width">
                <div className="row mb-4">
                  {getEmailMirrors()}
                </div>
              </div>

              <div className="mb-4">
                <h1>Email Scheduled</h1>
              </div>
              <div className="card card-full-width">
                <div className="row mb-4">
                  {getScheduledEmails()}
                </div>
              </div>

              <div className="mb-4">
                <h1>User Status History</h1>
              </div>
              <div className="card card-full-width">
                <div className="row mb-4">
                  {getStatusHistory()}
                </div>
              </div>

                </div>
              </div>
      );
    }
  };

  return (


    <div className="white-container mb-5 container">

      { getCustomerJourney()}


    </div>

  )
}

export default CustomerJourney

